import React, { useEffect, useRef } from "react"
import { navigate, Link } from "gatsby"
import { getApiEndpoint, isEDMode, getNested, getLocalizedURL } from '@src/helper';

// Style Imports
// import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"


const UnsubscribeConfirmed = ({location}) => {

    if (isEDMode) {
        location.state = {
            values: {
                email: '[name@domain.com]',
                unsubscribeAuriniaAlliance: true,
                unsubscribeDiseaseAwareness: true,
                unsubscribeCorporate: true
            }
        }
    }
    const locationState = useRef(location?.state?.values)
    useEffect(()=>{
        //gatsby isn't mapping location.state correctly on build so we need to do a nested check to see if email exists if not we redirect
        if(!isEDMode && !getNested(location,'state', 'values', 'email')) {
            navigate( getLocalizedURL('unsubscribe','es') );
        }
    },[])

    const resubscribe = (e) => {

        if(e.type === 'click' || e.code === 'Enter'){
            e.preventDefault()
            const unSubToken = location.state.token ? {token: location.state.token} : {}
            fetch(getApiEndpoint() + 'consumer/resubscribe', {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(unSubToken),
            })
            .then(async (resp) => {
                var r = await resp.json();
                if (r.success) {
                    navigate( getLocalizedURL('resubscribe-confirmed','es'),
                        {
                            state: {
                                values: location.state.values
                            }
                        }
                    );
                } else {
                    navigate( getLocalizedURL('500','es') );
                }
            })
            .catch((err) => {
                navigate( getLocalizedURL('500','es') );
            })
        }

    }

    return (
        <Layout addedClass="page--unsubscribe-confirmed">
            <Seo 
            title="Éxito - Cancelada | LUPKYNIS&reg; (voclosporina)" 
            description="" 
            robots="noindex" 
            canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('unsubscribe-confirmed','es')} />
            <Hero addedClass="bg-light-green hero--string">
                <div className="col-wrapper">
                    <div className="container">
                        <div className="hero__content">
                            <h1 className="heading heading--accent">Es oficial</h1>
                            <h2>Ha cancelado su suscripción</h2>

                            <p>{locationState?.current?.email}, ha elegido dejar de recibir comunicaciones de comercialización de LUPKYNIS.</p>
                            {(locationState?.current?.unsubscribeAuriniaAlliance || locationState?.current?.unsubscribeDiseaseAwareness || locationState?.current?.unsubscribeCorporate) && <p>También ha elegido cancelar su suscripción a las siguientes comunicaciones de comercialización:</p>}
                            <ul>
                                {locationState?.current?.unsubscribeAuriniaAlliance &&
                                    <li>Comunicaciones de comercialización sobre el apoyo al paciente de Aurinia Alliance<sup>&reg;</sup></li>
                                }
                                {locationState?.current?.unsubscribeDiseaseAwareness &&
                                    <li>Información de concientización sobre el estado de la enfermedad</li>
                                }
                                {locationState?.current?.unsubscribeCorporate &&
                                    <li>Comunicaciones corporativas de Aurinia, los fabricantes de LUPKYNIS</li>
                                }
                            </ul>
                            <p>Si cambió de opinión, puede volver a suscribirse <a tabIndex="0" onClick={resubscribe} onKeyDown={resubscribe}>aquí</a>. Puede volver a suscribirse más adelante.</p>
                            <Link to={getLocalizedURL('home','es')} className="button">Regresar al inicio</Link>
                        </div>
                        <div className="hero__image">
                            <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                        </div>
                    </div>
                </div>
            </Hero>
        </Layout>
    )
}

export default UnsubscribeConfirmed
